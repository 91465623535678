/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { STRING_STATS_TYPE } from 'apps/constants'
import { GUILD_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
import HorseStatsContainer from './styled'
import { RateStar } from 'features/Horse/components'
import { sortAbilities } from 'utils/helper'
import { HorseAbility } from 'models'

interface HorseStatsProps {
  data: any
}

const HorseStats = ({ data }: HorseStatsProps) => {
  const { t } = useTranslation()
  const [speed, setSpeed] = useState("")
  const [muscle, setMuscle] = useState("")
  const [stamina, setStamina] = useState("")
  const [agility, setAgility] = useState("")
  const [spirit, setSpirit] = useState("")
  const [iq, setIq] = useState("")

  useEffect(() => {
    if (data) {
      data?.horse?.list_horse_stats?.map((stat: any) => {
        switch (stat?.stats_type) {
          case STRING_STATS_TYPE.SPEED: {
            return setSpeed(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.MUSCLE: {
            return setMuscle(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.STAMINA: {
            return setStamina(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.AGILITY: {
            return setAgility(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.SPIRIT: {
            return setSpirit(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.IQ: {
            return setIq(() => stat.stat_rank)
          }
          default:
            break;
        }
      })
    }
  }, [data])

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []

    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }

    return rateStars
  }
  
  return (
    <HorseStatsContainer>
      <div className='content-left'>
        <div className='horse_runtype'>
          <p className='title'>{t(`${GUILD_MESSAGE}.horse_runtype`)}</p>
          <span>{data?.horse?.run_type ?? '---'} </span>
        </div>
        <div className='horse_runtype'>
          <p className='title'>{t(`${GUILD_MESSAGE}.horse_bloodline`)}</p>
          <span>{data?.horse?.bloodline?.name ?? '---'}</span>
        </div>
        <div className='horse_runtype'>
          <p className='title'>{t(`${GUILD_MESSAGE}.horse_characterist`)}</p>
          <span>{data?.horse?.characteristic_display ?? '---'}</span>
        </div>
        <div className='horse_runtype'>
          <p className='title'>{t(`${GUILD_MESSAGE}.horse_color`)}</p>
          <span>{data?.horse?.bloodline?.color ?? '---'}</span>
        </div>
      </div>
      <div className='content-right'>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_speed`)}:</span>
          <span className='content_stats'>{speed ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_muscle`)}:</span>
          <span className='content_stats'>{muscle ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_stamina`)}:</span>
          <span className='content_stats'>{stamina ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_agility`)}:</span>
          <span className='content_stats'>{agility ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_spirit`)}:</span>
          <span className='content_stats'>{spirit ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_iq`)}:</span>
          <span className='content_stats'>{iq ?? '---'}</span>
        </div>

        {data?.horse && sortAbilities(data?.horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
          <div key={index} className='stats'>
            <span className='title title__stats'>{skill?.name_en}:</span>
            <span className='content_stats d-flex align-items-center'>{generateRateLevel(3, skill?.level)}</span>
          </div>
        ))}
      </div>
    </HorseStatsContainer>
  )
}

export default HorseStats

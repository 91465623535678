/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'antd'
import { useEffect, useState } from 'react'

import Item from '../ItemBox/item'
import { Container, ModalContainer } from './styled'

import ListItem from '../ListItem'
import Head from '../Head'
import { checkExistItem, getImageItem, isUseItem } from '../utils'
import ItemDetail from '../ItemDetail'

interface iprops {
  type: string
  horseItems: any
  onSuccess: () => void
  horseId: number
}

const HeadArmor = ({ type, horseItems, onSuccess,  horseId }: iprops) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalDetail, setShowModalDetail] = useState(false)
  const [checkImg, setCheckImage] = useState("")
  const [src, setSrc] = useState("")

  const checkExistItemResult = checkExistItem(type, horseItems)

  useEffect(() => {
    setSrc(() => getImageItem(type, horseItems))
    setCheckImage(() => isUseItem(type, horseItems) ? "" : "default")
  }, [type, horseItems])

  const _onToggleModal = () => {
    if (checkExistItemResult) {
      setShowModalDetail(!showModalDetail)
    } else {
      setShowModal(!showModal)
    }
  }

  const _onSuccess = () => {
    setShowModal(false)
    setShowModalDetail(false)

    onSuccess();
  }

  const _onCloseModal = () => {
    setShowModal(false);
    onSuccess();
  }

  return (
    <Container>
      <Item imgUrl={src} onClickItem={_onToggleModal} className={`${type === 'SKIN' ? 'SKIN' : ''}`} imgDefault={checkImg} />
      <Modal
        open={showModal}
        onCancel={_onToggleModal}
        footer={false}
        width={615}
        centered
        className='modal-item'
        style={{ background: 'transparent' }}
        bodyStyle={{
          background: 'transparent',
          padding: 0
        }}
      >
        <ModalContainer>
          <Head name='Select item' />
          <span className='custom-text color-white d-flex justify-content-center'>Choose the item you want to use.</span>
          <ListItem type={type} horseId={horseId} onItemChooseSuccess={_onCloseModal} />
        </ModalContainer>
      </Modal>
      <ItemDetail
        open={showModalDetail}
        onCancel={_onToggleModal}
        itemToken={checkExistItemResult?.item_token}
        isUsedItem
        onSuccess={_onSuccess}
        type={type}
        className={type}
        horseId={horseId}
      />
    </Container>
  )
}

export default HeadArmor

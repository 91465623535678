/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-explicit-any */
import horseApi from 'apis/horseApi'
import lendingApi from 'apis/lending'
import userApi from 'apis/userApi'
import { configs } from 'apps'
import { CURRENCY_TYPE, LENDING_USER_TYPE, STATUS_TRANSACTION, TIME_CONFIGS, timeCheckNonce } from 'apps/constants'
import { GAME_TOKEN_KUDA } from 'assets/images'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import LendingTable from 'features/Market/components/LendingTable'
import { RequestLoginModal } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import SkeletonDetail from 'features/components/SkeletonDetail'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import horseFarmABI from 'json/HorseFarm.json'
import horseNFTABI from 'json/HorseNFT.json'
import horseFarmABI_V2 from 'json/HorseFarm_V2.json'
import horseNFTABI_V2 from 'json/HorseNFT_V2.json'
import _ from 'lodash'
import {
  ApiResponse,
  ELeaseType,
  LENDING_STATUS,
  LENDING_TYPE,
  LendingHorse,
  LendingHorseInfo,
  LendingHorseMarket,
  LendingPriceConfigs,
  MODAL_TYPE,
  notificationTemplate,
  CurrentUser
} from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BtnBack from 'shared/BtnBack'
import Button from 'shared/Button'
import { convertGasPrice, convertPluralText, convertStatusLending, handleAsyncRequest } from 'utils/helper'
import openNotification from 'utils/toast'
import HorseAvatar from '../../../Horse/components/HorseAvatar'
import HorseBodyInfoLending from '../../../Horse/components/HorseBodyInfoLending'
import { getCurrentUser } from 'utils/metamask'
import ModalConfirmLendingCommon from 'features/Guild/components/ModalConfirmGuildCommon'
import { convertFullLongDate } from 'utils/time'
import VipIcon from 'features/components/VipIcon'
import HorseModalAvailableStyled, { ConfirmBorrowStyle } from './styled'

const contract = {
  horseNFT: configs.horseNFT,
  horseNFT_v2: configs.horseNFT_v2,
  horseFarm: configs.horseFarm,
  horseFarm_v2: configs.horseFarm_v2,
  transporter: configs.transporter
}

function HorseModalAvailable() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { horse_id } = useParams()

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const auth = useAppSelector(state => state.auth)

  const [horse, setHorse] = useState<LendingHorse>()
  const [lendingInfo, setLendingInfo] = useState<LendingHorseInfo>()
  const [lendingHorseInfo, setLendingHorseInfo] = useState<LendingPriceConfigs[]>(TIME_CONFIGS)

  const [isLoading, setIsLoading] = useToggle(false)
  const [isleaseHorse, setIsLeaseHorse] = useState<boolean>(false)

  const [resultLeaseHorse, setResultLeaseHorse] = useState<any>()
  const [resultWithdrawHorse, setResultWithdrawHorse] = useState<any>()
  const [openConfirmWithDrawModal, toggleOpenConfirmWithDrawModal] = useToggle(false)
  const [openConfirmFarmingModal, toggleOpenConfirmFarmingModal] = useToggle(false)
  const [isApproveContract, setIsApproveContract] = useState<boolean>(false)

  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const [openNotificationModal, toggleOpenNotificationModal] = useToggle(false)
  const [openNotificationHorseDisableModal, toggleOpenNotificationHorseDisableModal] = useToggle(false)
  const [message, setMessage] = useState<notificationTemplate>()

  const [isLendingShow, setLendingShow] = useState(true)
  const [isSendToMarketModal, togggleIsSendToMarketModal] = useToggle(false)
  const [isBorrowModal, toggleIsBorrowModal] = useToggle(false)
  const [isCancelLendingModal, toggleIsCancelLendingModal] = useToggle(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [isHorseInRace, toggleIsHorseInRace] = useToggle(false)

  const [lendingType, setLendingType] = useState('')
  const [isDisable, toggleIsDisable] = useToggle(false)
  const [value, setValue] = useState<LendingPriceConfigs>()
  const [btnLoading, setBtnLoading] = useToggle(false)
  const [messageError, setMessageError] = useState('')
  const [chooseUserType, setChooseUserType] = useState(LENDING_USER_TYPE.ALL_USER)
  const [userAddress, setUserAddress] = useState('')
  const [currentUser, setCurrentUser] = useState<CurrentUser>()
  const [tokenId, setTokenId] = useState(0)

  useEffect(() => {
    fetchHorse()
  }, [horse_id])

  useEffect(() => {
    setChooseUserType(lendingInfo?.target_user ? LENDING_USER_TYPE.TARGET_USER : LENDING_USER_TYPE.ALL_USER)
  }, [lendingInfo])

  useEffect(() => {
    if (!value && !lendingHorseInfo?.some(value => value.active === true)) {
      setValue(undefined)
    }

    if (value && parseInt(value?.value) >= 0) {
      toggleIsDisable(false)
      return
    }
    toggleIsDisable(true)
  }, [value])

  useEffect(() => {
    if (!isleaseHorse) return
    createLeaseHorseContract()
  }, [isleaseHorse])

  useEffect(() => {
    if (isApproveContract === true) {
      handleLeaseHorseContract()
    }
  }, [isApproveContract])

  const fetchHorse = async () => {
    setIsLoading(true)
    const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      lendingApi.getHorseLendingDetails(horse_id || '')
    )
    if (error) {
      if (error.code === 400) {
        setMessage?.({
          body: error.message,
          title: MODAL_TYPE.failed
        })
      }
      toggleOpenNotificationHorseDisableModal?.(true)
    }    

    if (horseResponse) {
      const fetchedHorse = horseResponse.data
      if (convertStatusLending(fetchedHorse.lending_info.lending_status) === LENDING_STATUS.InFarm) {
        setLendingShow(false)
      }
      setHorse(fetchedHorse.horse)
      setLendingInfo(fetchedHorse?.lending_info)
      const priceConfigs = fetchedHorse?.lending_info?.price_configs
      const activeFirstDay =
        !_.isEmpty(priceConfigs) &&
        priceConfigs.map((v: any, index: number) => {
          if (index === 0) {
            setValue({ ...v, active: true })
            return { ...v, active: true }
          } else {
            return { ...v, active: false }
          }
        })
      setTokenId(fetchedHorse?.horse?.token_id)
      setLendingHorseInfo(activeFirstDay || TIME_CONFIGS)
    }
    setIsLoading(false)
  }

  const handleLeaseHorse = async () => {
    toggleOpenConfirmFarmingModal(false)
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(lendingApi.postLeaseHorse({ horse_id: horse_id }))
    setBtnLoading(false)
    if (error) {
      if (error.code === 404) {
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.pleaseComeBack`),
          title: MODAL_TYPE.failed
        })
      } else setMessage?.({ body: error.message, title: MODAL_TYPE.failed })
      toggleOpenNotificationModal?.(true)
    }
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      setMessage({ title: 'FARMING' })
      setResultLeaseHorse(result.data)
      setIsLeaseHorse(true)
    }
  }  

  const checkNonceWithDrawContract = async (nonce: string, type: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(lendingApi.postCheckNonce({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        fetchHorse()
        pushNotification(
          t(`${LENDING_MESSAGE}.${type === 'withdraw' ? 'successWithdrawHorse' : 'successFarming'}`),
          true
        )
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          setMessage?.({
            body: t(`${NOTIFICATION_MESSAGE}.transferredPending`),
            title: 'warning'
          })
          toggleOpenNotificationModal?.(true)
        }
      }
    }, timeCheckNonce)
  }

  const createLeaseHorseContract = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      if (!resultLeaseHorse) return
      let leaseHorseContract: any = '';
      if (resultLeaseHorse?.horse_contract?.toUpperCase() === contract.horseNFT?.toUpperCase()) {
        leaseHorseContract = new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
      } else {
        leaseHorseContract = new ethers.Contract(contract.horseNFT_v2, horseNFTABI_V2.contract.abi, signer)
      }
      try {
        const tx = await leaseHorseContract.approve(contract.transporter, tokenId || '', {
          gasPrice: convertGasPrice(resultLeaseHorse.gas_price)
        })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
        }
        setIsApproveContract(true)
      } catch (err) {
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
        toggleOpenInProgressBalanceModal(false)
      }
      setIsLeaseHorse(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleLeaseHorseContract = async () => {
    if (!resultLeaseHorse) return
    try {
      const leaseContract = new ethers.Contract(contract.horseFarm_v2, horseFarmABI_V2.contract.abi, signer);
      const coverHorseId = new BigNumber(tokenId || '').toFixed()
      const coverBlockExpired = new BigNumber(resultLeaseHorse.block_expired).toFixed()
      
      try {
        await leaseContract.lease(
          {
            owner: resultLeaseHorse.owner,
            horseAddress: resultLeaseHorse.horse_contract,
            horseId: coverHorseId,
            blockExpired: coverBlockExpired,
            nonce: resultLeaseHorse.nonce,
            v: resultLeaseHorse.v,
            r: resultLeaseHorse.r,
            s: resultLeaseHorse.s
          },
          { gasPrice: convertGasPrice(resultLeaseHorse.gas_price) }
        )
        checkNonceWithDrawContract(resultLeaseHorse.nonce, 'Farming')
      } catch (err) {
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
        toggleOpenInProgressBalanceModal(false)
      }
      setIsApproveContract(false)
    } catch (err) {
      setIsApproveContract(false)
    }
  }

  const handeLeaseType = () => {
    if (lendingInfo?.lending_type === LENDING_TYPE.Available) return ELeaseType.Available
    if (lendingInfo?.lending_type === LENDING_TYPE.Share) return ELeaseType.Share
    return ''
  }

  const onShowModalCancelLending = () => {
    setMessage({ body: t(`${LENDING_MESSAGE}.cancelLendingConfirm`), title: 'Cancel lending' })
    toggleIsCancelLendingModal(true)
  }

  const handleBtnLendingClick = () => {
    setLendingShow(true)
    setLendingHorseInfo(TIME_CONFIGS)
  }

  const buttonLayout = () => {
    if (!auth.isLogged) {
      return
    }
    switch (convertStatusLending(lendingInfo?.lending_status)) {
      case LENDING_STATUS.Available:
        return (
          <Button
            buttonName='Farming'
            onClickButton={() => toggleOpenConfirmFarmingModal(true)}
            isLoading={btnLoading}
          />
        )
      case LENDING_STATUS.InFarm:
        return (
          <>
            {lendingInfo?.is_owner ? (
              <>
                <Button buttonName='Lending Setting' onClickButton={handleBtnLendingClick} disabled={btnLoading} />
                <Button
                  buttonName='Withdraw to wallet'
                  onClickButton={() => toggleOpenConfirmWithDrawModal(true)}
                  type="btnCancel"
                  isLoading={btnLoading}
                />
              </>
            ) : (
              ''
            )}
          </>
        )
      default:
        return ''
    }
  }

  const hanldeChangeTable = (item: any, type: string) => {
    if (type === 'checked') {
      const newState = lendingHorseInfo.map(obj => {
        if (convertStatusLending(lendingInfo?.lending_status) !== LENDING_STATUS.InFarm) {
          if (obj.day === item.day) {
            if (obj.active === true) {
              return { ...obj, active: obj.active }
            }
            setValue(item)
            return { ...obj, active: !obj.active }
          }
        } else if (obj.id === item.id) {
          setValue(item)
          return { ...obj, active: !obj.active }
        }
        if (convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.InFarm) {
          return { ...obj }
        } else return { ...obj, active: false }
      })

      setLendingHorseInfo(newState)
    }
    if (type === 'price') {
      const newState = lendingHorseInfo.map(obj => {
        if (obj.id == item.target.id) {
          return { ...obj, value: item.target.value }
        }
        return { ...obj }
      })
      setLendingHorseInfo(newState)
    }
    if (type === 'day') {
      const newState = lendingHorseInfo.map(obj => {
        if (obj.id == item.target.id) {
          return { ...obj, day: item.target.value }
        }
        return { ...obj }
      })
      setLendingHorseInfo(newState)
    }
  }

  const handleParamsPriceConfigs = lendingHorseInfo.reduce((prev: any, cur) => {
    const { value, day, active } = cur
    if (active === true) {
      prev.push({ value, day })
    }
    return prev
  }, [])

  const handleSendToMarket = async () => {
    setBtnLoading(true)
    const payload = {
      lending_type: lendingType ? lendingType : LENDING_TYPE.Available,
      price_configs: handleParamsPriceConfigs,
      ...(userAddress && {
        target_user: userAddress
      })
    }
    const [error, result]: any = await handleAsyncRequest(lendingApi.postSendToMarket(horse_id || '', payload))
    setBtnLoading(false)
    if (error) {
      togggleIsSendToMarketModal(false)
      toggleOpenNotificationModal(true)
      setMessage({ body: error.code ? error.message : error?.errors?.[0]?.message, title: MODAL_TYPE.failed })
    }
    if (result) {
      togggleIsSendToMarketModal(false)
      pushNotification(t(`${LENDING_MESSAGE}.successRentalHorse`), true)
      fetchHorse()
      setLendingType(LENDING_TYPE.Available)
    }
  }

  const handleConfirmSendToMarket = () => {
    const dayArr = handleParamsPriceConfigs.map((item: any) => {
      return parseInt(item.day)
    })
    const isZero = dayArr.some((item: string) => {
      if (+item <= 0 || +item >= 365) {
        return true
      } else return false
    })
    const isNull = dayArr.some((item: string) => {
      if (!item) {
        return true
      }
    })
    const isDuplicate = dayArr.some((item: string, idx: number) => {
      return dayArr.indexOf(item) != idx
    })
    if (isNull) {
      setMessageError(t(`${LENDING_MESSAGE}.enterHorseRentalTime`))
      return
    }
    if (isZero) {
      setMessageError(t(`${LENDING_MESSAGE}.enterFrom1to364`))
      return
    }
    if (isDuplicate) {
      setMessageError(t(`${LENDING_MESSAGE}.enterDatesCannotBeSame`))
      return
    }

    if (lendingType !== LENDING_TYPE.Share) {
      const valueArr = handleParamsPriceConfigs.map((item: any) => {
        return item.value
      })
      const checkValue = (element: string) => parseInt(element) <= 0
      if (valueArr.some(checkValue)) {
        setMessageError(t(`${LENDING_MESSAGE}.enterPriceThan0`))
        return
      }
    }

    togggleIsSendToMarketModal(true)
    setMessageError('')
    setMessage({ body: t(`${LENDING_MESSAGE}.lendThisHorseConfirm`) })
  }

  const onShowModalBorrow = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen(true)
      return
    }
    toggleIsBorrowModal(true)
    setMessage({ title: 'Borrowing' })
  }

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser?.data))
  }

  const handleHorseRent = async () => {
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      lendingApi.postHorseRent(horse_id || '', {
        day: value?.day
      })
    )
    setBtnLoading(false)
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen(true)
        return
      }
      setMessage({ body: error?.message, title: MODAL_TYPE.failed })
      toggleOpenNotificationModal(true)
    }
    if (result) {
      // borrow success
      fetchCoinUser()
      pushNotification(t(`${LENDING_MESSAGE}.horseJoinRaceConfirm`), true)
      fetchHorse()
    }
    toggleIsBorrowModal(false)
  }

  const checkHorseIsInRace = async () => {
    setBtnLoading(true)
    const [, result]: any = await handleAsyncRequest(horseApi.getHorseIsInRace(horse_id || '', { statuses: 'OPEN' }))
    setBtnLoading(false)
    if (result?.data?.is_in_race) {
      toggleIsHorseInRace(true)
      setMessage({ body: t(`${LENDING_MESSAGE}.successRentedHorse`), title: MODAL_TYPE.warning })
      toggleIsCancelLendingModal(false)
      return
    } else {
      handleHorseBackToFarm()
    }
  }

  const handleHorseBackToFarm = async () => {
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(lendingApi.postHorseBackToFarm(horse_id || '', { current_status: lendingInfo?.lending_status }))
    setBtnLoading(false)
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen(true)
      } else if (error.code === 'E2001' || error?.message?.includes('Only the horse that is being borrowed can perform this operation. Please check horse status')) {
        toggleOpenNotificationModal(true)
        setMessage({ body: error?.message, title: MODAL_TYPE.failed })
        fetchHorse()
      } else {
        toggleOpenNotificationModal(true)
        setMessage({ body: error?.message, title: MODAL_TYPE.failed })
      }
    }
    if (result) {
      pushNotification(t(`${LENDING_MESSAGE}.successCancelLending`), true)
      fetchHorse()
      fetchCoinUser()
    }
    toggleIsCancelLendingModal(false)
    toggleIsHorseInRace(false)
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const handleBtnWithdrawClick = async () => {
    toggleOpenConfirmWithDrawModal(false)
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(lendingApi.postWithrawHorse({ horse_id }))
    setBtnLoading(false)
    if (error) {
      if (error.code === 400) {
        setMessage?.({
          body: error.message,
          title: MODAL_TYPE.failed
        })
      }
      toggleOpenNotificationModal(true)
    }
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      setMessage({ title: 'WITHDRAW' })
      setResultWithdrawHorse(result.data)
    }
  }

  useEffect(() => {
    if (!resultWithdrawHorse) return
    handleWithDrawHorseContract()
  }, [resultWithdrawHorse])

  const handleWithDrawHorseContract = async () => {
    if (!resultWithdrawHorse) return
    try {
      const coverHorseId = new BigNumber(tokenId || '').toFixed()
      const coverBlockExpired = new BigNumber(resultWithdrawHorse.block_expired).toFixed()
      let leaseContract: any = '';
      let param: any = '';
      if (resultWithdrawHorse?.horse_farm_address?.toUpperCase() === contract.horseFarm?.toUpperCase()) {
        leaseContract = new ethers.Contract(contract.horseFarm, horseFarmABI.contract.abi, signer)
        param = {
          owner: resultWithdrawHorse.owner,
          horseId: tokenId,
          blockExpired: coverBlockExpired,
          nonce: resultWithdrawHorse.nonce,
          v: resultWithdrawHorse.v,
          r: resultWithdrawHorse.r,
          s: resultWithdrawHorse.s
        }
      } else {
        leaseContract = new ethers.Contract(contract.horseFarm_v2, horseFarmABI_V2.contract.abi, signer)
        param = {
          owner: resultWithdrawHorse.owner,
          horseAddress: resultWithdrawHorse?.horse_contract,
          horseId: coverHorseId,
          blockExpired: coverBlockExpired,
          nonce: resultWithdrawHorse.nonce,
          v: resultWithdrawHorse.v,
          r: resultWithdrawHorse.r,
          s: resultWithdrawHorse.s
        }
      }
      
      try {
        await leaseContract.withdraw(
          param,
          { gasPrice: convertGasPrice(resultWithdrawHorse.gas_price) }
        )
        checkNonceWithDrawContract(resultWithdrawHorse.nonce, 'withdraw')
      } catch (err: any) {
        if (err?.code && err?.code === 4001) {
          setMessage?.({
            body: t(`${LENDING_MESSAGE}.failedTransaction`),
            title: MODAL_TYPE.failed
          })
          toggleOpenNotificationModal?.(true)
          toggleOpenInProgressBalanceModal(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleBtnBackClick = () => {
    if (
      convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.Borrowed &&
      lendingInfo?.is_owner === false
    ) {
      navigate('/lending/my-borrow')
      return
    }
    if (convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.InFarm) {
      navigate('/lending/my-asset')
      return
    }
    navigate(-1)
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }

  const onChangeUserType = (e: any) => {
    const type = e.target.value
    setChooseUserType(type)
    setMessageError('')

    if (type === LENDING_USER_TYPE.ALL_USER) {
      setUserAddress('')
    }
  }

  const onChangeAddress = (e: any) => {
    const value = e.target.value
    const length = value.length

    if (value && !value.startsWith('0x', 0)) {
      setMessageError('This is not a wallet address. Please enter wallet address starting with 0x.')
    } else if (value && (length < 34 || length > 62)) {
      setMessageError('Length 34 ~ 62 charactor')
    } else {
      setMessageError('')
      setUserAddress(value)
    }
  }

  const fetchCurrentUser = async () => {
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    setCurrentUser(currentUser)
  }

  useEffect(() => {
    fetchCurrentUser()
  }, [])

  const isShowSettingItemDoping = (
      (currentUser && currentUser?.id === horse?.user?.id) 
      && (horse?.own_status === LENDING_STATUS.Lending || horse?.own_status === LENDING_STATUS.Owner)
      && (lendingInfo?.lending_status === LENDING_STATUS.InFarmStatus || lendingInfo?.lending_status === LENDING_STATUS.Available))
  
  return (
    <div className='container'>
      <HorseModalAvailableStyled>
        <BtnBack onBack={handleBtnBackClick} />

        {isLoading && <SkeletonDetail />}
        {horse && !isLoading && (
          <div className='horse-detail-container'>
            <div className='quick-view-left'>
              <HorseAvatar horse={horse} lendingInfo={lendingInfo} />
            </div>
            <div 
              className={`quick-view-center ${isShowSettingItemDoping ? 'only-view' : ''} ${isLendingShow && lendingInfo?.lending_status !== LENDING_STATUS.Available ? 'setting-lendding' : ''}`}>
              <div className='right-container color-white'>
                <HorseBodyInfoLending 
                  horse={horse} 
                  isLending={true}
                  fetchHorse={fetchHorse}
                  isShowSettingItemDoping={isShowSettingItemDoping}
                />
                {convertStatusLending(lendingInfo?.lending_status) !== LENDING_STATUS.Available && isLendingShow ? (
                  ''
                ) : (
                  <div className='btn-bottom'>{buttonLayout()}</div>
                )}
              </div>
            </div>

            {convertStatusLending(lendingInfo?.lending_status) !== LENDING_STATUS.Available && isLendingShow ? (
              <div className='quick-view-right'>
                <div className='right color-white'>
                  <div className='right-body'>
                    <LendingTable
                      timeLending={lendingHorseInfo || []}
                      onTimeLending={setLendingHorseInfo}
                      leaseType={handeLeaseType()}
                      lendingInfo={lendingInfo}
                      onChange={hanldeChangeTable}
                      onLendingType={setLendingType}
                      disabledSelect={
                        convertStatusLending(lendingInfo?.lending_status) !== LENDING_STATUS.InFarm ? true : false
                      }
                      messageError={messageError}
                      onSetMessageError={setMessageError}
                      onChangeUserType={onChangeUserType}
                      valueUserType={chooseUserType}
                      onChangeAddress={onChangeAddress}
                      isSettingLending={isLendingShow}
                      horse={horse}
                    />
                  </div>
                </div>
                <div className='right-bottom'>
                  {convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.InFarm && (
                    <Button
                      buttonName='Confirm'
                      onClickButton={handleConfirmSendToMarket}
                      isLoading={btnLoading}
                      disabled={
                        _.isEmpty(handleParamsPriceConfigs) ||
                        (chooseUserType === 'TARGET_USER' && !userAddress) ||
                        (!!messageError && (messageError !== t(`${LENDING_MESSAGE}.enterPriceThan0`) && messageError !== t(`${LENDING_MESSAGE}.enterHorseRentalTime`)))
                      }
                    />
                  )}
                  {
                    // ((convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.Lending && lendingInfo?.is_owner) ||
                    //   (convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.Borrowed && lendingInfo?.lending_type === LENDING_TYPE.Share && lendingInfo?.is_owner)) &&
                    (lendingInfo?.is_owner || auth?.user_id === lendingInfo?.renter?.id) &&
                    lendingInfo?.lending_type && (
                      <Button
                        buttonName={`${convertStatusLending(lendingInfo?.lending_status) === LENDING_STATUS.Borrowed
                          ? (lendingInfo.is_owner ? 'Cancel Renting' : 'Cancel Borrowing')
                          : 'Cancel Lending'
                          }`}
                        onClickButton={onShowModalCancelLending}
                        type="btnCancel"
                        isLoading={btnLoading}
                      />
                    )
                  }
                  {lendingInfo?.lending_status === LENDING_STATUS.Lending &&
                    !lendingInfo?.is_owner &&
                    (!lendingInfo.target_user ||
                      (lendingInfo.target_user && lendingInfo.target_user.id === auth.user_id)) && (
                      <Button
                        buttonName='Borrow'
                        onClickButton={onShowModalBorrow}
                        disabled={isDisable}
                        isLoading={btnLoading}
                      />
                    )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}

        {openInProgressBalanceModal && <InProgressBalanceModal title={`${message?.title} IN PROGRESS`} />}
        {openNotificationModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOpenNotificationModal}
            onCloseButtonClick={toggleOpenNotificationModal}
            onConfirm={toggleOpenNotificationModal}
            message={<span> {message?.body} </span>}
            title={message?.title}
          />
        )}

        {isSendToMarketModal && (
          <ConfirmOkModal
            toggleIsModalOpen={togggleIsSendToMarketModal}
            onCloseButtonClick={togggleIsSendToMarketModal}
            onConfirm={handleSendToMarket}
            message={<span> {message?.body} </span>}
            title={message?.title}
            isLoading={btnLoading}
            heading='Setting Lending'
          />
        )}
        {isCancelLendingModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleIsCancelLendingModal}
            onCloseButtonClick={toggleIsCancelLendingModal}
            onConfirm={checkHorseIsInRace}
            heading='warning'
            btnCancel='CANCEL'
            btnOk='OK'
            message={
              <div className='font-size-18'>
                <pre className='font-book'>
                  {lendingInfo?.is_owner &&
                    lendingInfo?.lending_type === 'AVAILABLE_PRICING' &&
                    lendingInfo?.lending_status === LENDING_STATUS.Borrowed ? (
                    <>
                      To cancel this lending, you'll have to return {lendingInfo?.rent_fee || 0}
                      <img width={20} src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' /> to renter. <br />
                      Do you still want to process?
                    </>
                  ) : (
                    <>
                      {!lendingInfo?.is_owner && lendingInfo?.lending_type === 'AVAILABLE_PRICING' ? (
                        <>{t(`${LENDING_MESSAGE}.cancelLendingRenterConfirm`)}</>
                      ) : (
                        <>
                          {' '}
                          {lendingInfo?.lending_status === LENDING_STATUS.Borrowed ? (
                            <>{t(`${LENDING_MESSAGE}.cancelLendingConfirmBorrowed`)}</>
                          ) : (
                            <>{t(`${LENDING_MESSAGE}.cancelLendingConfirmMess`)}</>
                          )}
                        </>
                      )}
                    </>
                  )}
                </pre>
              </div>
            }
            isLoading={btnLoading}
          />
        )}
        {isBorrowModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleIsBorrowModal}
            onCloseButtonClick={toggleIsBorrowModal}
            onConfirm={handleHorseRent}
            message={
              <ConfirmBorrowStyle>
                <div className='text-start ps-3'>
                  <div className='confirm-borrow-question' style={{ fontSize: '20px' }}> {t(`${LENDING_MESSAGE}.reallyRentHorseConfirm`)}</div>
                  <div className='font-size-24' style={{ fontSize: '20px' }}>
                    {t(`${LENDING_MESSAGE}.totalRentalDays`)}&nbsp;: &nbsp; {value && convertPluralText(parseInt(value.day), 'day')}
                  </div>
                  <div className='font-size-24' style={{ fontSize: '20px' }}>
                    {t(`${LENDING_MESSAGE}.totalRentalPrice`)}&nbsp;: &nbsp; {value?.value}{' '}
                    {lendingInfo?.lending_type === LENDING_TYPE.Available ? (
                      <span className='unit'>
                        <img width={20} src={GAME_TOKEN_KUDA} alt='kuda' className='kuda' />
                      </span>
                    ) : (
                      '%'
                    )}
                  </div>
                  {horse?.msp_details?.msp && (<>
                    <div className='font-size-24 vip_expired'>
                      <div className='vip-icon'>
                        <VipIcon />
                      </div>
                      <div className='text__vip_contain'>
                        <span className='text__vip'>{t(`${LENDING_MESSAGE}.mspExpiredAt`)}</span>&nbsp; &nbsp; 
                        <span>{convertFullLongDate((+horse?.msp_details?.msp_expired_at * 1000) || new Date())}</span>
                      </div>
                    </div>
                    <div className='text__vip text_align_center'>{t(`${LENDING_MESSAGE}.mspExpiredChange`)}</div>
                  </>)}
                </div>
              </ConfirmBorrowStyle>
            }
            title={message?.title}
            isLoading={btnLoading}
            btnOk='Approve'
            btnCancel='Cancel'
          />
        )}
        {openConfirmWithDrawModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOpenConfirmWithDrawModal}
            onCloseButtonClick={toggleOpenConfirmWithDrawModal}
            onConfirm={handleBtnWithdrawClick}
            message={
              <div className='font-size-24  message-content'>
                {`Metahorse Farm Service

                When farming, racehorse NFTs are transacted to the lending pool.
                A fee (${CURRENCY_TYPE.ADIL}) is required for farming.
                After farming, you will not be able to:
                ・Level up
                ・Participating in races

                Withdraw the transaction from the lending pool to your wallet
                A processing fee (${CURRENCY_TYPE.ADIL}) is also required for withdraw.

                Do you want withdraw horse from farm?`}
              </div>
            }
          />
        )}
        {openNotificationHorseDisableModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOpenNotificationHorseDisableModal}
            onCloseButtonClick={handleNavigateBack}
            message={<span> {message?.body} </span>}
            title={message?.title}
          />
        )}

        {openConfirmFarmingModal &&
          <ModalConfirmLendingCommon
            message={
              <div>
                Metahorse Farm Service 
                <br />
                When farming, racehorse NFTs are transacted to the lending pool.
                <br />
                A fee (ADIL) is required for farming. 
                <br />
                Before put into farm, the armor and skin will be removed from the horse.
                <br />
                After farming, you will not be able to: 
                <br />
                ・Level up 
                <br />
                ・Participating in races
                <br />
                Withdraw the transaction from the lending pool to your wallet A processing fee (ADIL) is also required for withdraw. 
                <br />
                <br />
                Do you want submit(send) horse to farm?
              </div>
            }
            onClose={toggleOpenConfirmFarmingModal}
            onConfirm={handleLeaseHorse}
            loading={isLoading}
            size='XL'
            title={t(`REGISTER HORSE TO LENDING FARM`)}
          />
        }
        
        {isHorseInRace && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleIsHorseInRace}
            onCloseButtonClick={toggleIsHorseInRace}
            onConfirm={handleHorseBackToFarm}
            message={<span> {message?.body} </span>}
            title={message?.title}
            isLoading={btnLoading}
          />
        )}
        {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
      </HorseModalAvailableStyled>
    </div>
  )
}

export default HorseModalAvailable

export default {
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ID_KEY: 'user_id',
  HEROKU_BY_PASS: 'https://heroku-bypass.herokuapp.com/',
  DEBOUNCE_TIME: 500,
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    BAD_REQUEST_429: 429
  },
  TRANSACTION: 'transaction',
  SIGNER: 'signer',
  CLICKLOGIN: 'clickLogin'
}

export enum STATUS {
  WAITING = 'WAITING',
  SCHEDULING = 'SCHEDULING',
  LIVE = 'LIVE'
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

export const LONG_DATE = 'DD/MM/YYYY HH:mm:ss'

export const FULL_DATE_LOCAL = 'DD/MM/YYYY[T]HH:mm:ss.SSS+07:00'

export const SHORT_DAY = 'DD/MM/YYYY'

export const SHORT_TIME = 'HH:mm'

export const SHORT_TIME_MINUS = 'mm:ss'

export const LONG_TIME = 'HH:mm:ss'

export const MAX_TIME_ENERGY = 54000000

export const MAX_ID = 2147483647

export const TIME_CLOSE_MODAL = 5000

export const TIME_CLOSE_STATS_MODAL = 3000

export const SPECTRE_RPC = '55'

export const SPEED_UP = 1.5

export const minInput = '0.00001'

export const maxInput = '9999999999.99999'

export const exceptThisSymbols = ['e', 'E', '+', '-', ',']

export const timeCheckNonce = 5000

export const decimalFormatCoin = 5

export const timeLineEndAt = 5000

export const optionPercent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

export const MAX_HORSE_LEVEL = 20

export const TIME_NOTIFICATION = 20000

export const MailStatusOptions = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: 'Read',
    isActive: false
  },
  {
    name: 'Unread',
    isActive: false
  }
]

export const MAX_PRICE_MARE = 1000000
export const TIME_CONFIGS = [
  { name: '1', day: '1', id: 1, active: false, value: '0' },
  { name: '3', day: '3', id: 2, active: false, value: '0' },
  { name: '7', day: '7', id: 3, active: false, value: '0' },
  { name: '15', day: '15', id: 4, active: false, value: '0' },
  { name: '30', day: '30', id: 5, active: false, value: '0' }
]

export const TIME_GUILD_CONFIGS = [
  { name: '1', day: '1', id: 1, active: false },
  { name: '3', day: '3', id: 2, active: false },
  { name: '7', day: '7', id: 3, active: false },
  { name: '15', day: '15', id: 4, active: false },
  { name: '30', day: '30', id: 5, active: false }
]

export const TIME_CONFIGS_PRIVATE = [{ name: '1', day: '1', id: 1, active: true, value: '0' }]

export const CURRENCY_TYPE = {
  ADIL: 'ADIL',
  MARE: 'MARE',
  EMAS: 'EMAS',
  KUDA: 'KUDA',
  MERAH: 'MERAH',
  BIRU: 'BIRU',
  KUDA_NEW: 'KUDA_NEW',
  MERAH_NEW: 'MERAH_NEW'
}

export const STRING_REGION = {
  TOKYO: 'TOKYO',
  SEOUL: 'SEOUL',
  Tokyo: 'Tokyo',
  Seoul: 'Seoul'
}

export const STATS_TYPE = ['SPEED', 'MUSCLE', 'STAMINA', 'AGILITY', 'SPIRIT', 'IQ']
export const TWO_STATS_TYPE = ['SPEED', 'AGILITY', 'MUSCLE', 'SPIRIT', 'STAMINA', 'IQ']

export const OPERATING_SYSTEM = {
  window: 'WindowsPhone',
  android: 'Android',
  testflight: 'testflight',
  unknown: 'unknown'
}

export const LINK_APP = 'metahorse://web3login'

export const REGEX_NICK_NAME = '^[a-zA-Z0-9 ]{0,20}$'
export const REGEX_TAG = '^[a-zA-Z0-9]{0,3}$'
export const REGEX_FIRST_SPACE = /^\s/
export const REGEX_LAST_SPACE = /\s+$/
export const REGEX_TWO_SPACE = /\s{2,}/g

export const STATUS_TRANSACTION = {
  pending: 'PENDING',
  expired: 'EXPIRED',
  success: 'SUCCESS'
}

export const ACTION_REJECTED = 'ACTION_REJECTED'

export const TIME_TRANSACTION = 30000

export const SCREEN = {
  lending: 'LENDING',
  specialRace: 'SPECIAL_RACE'
}

export const GAS_CONFIG = 1000000000
export const TIME_ONE_DAY = 100000000

export enum ROLE_GUILD {
  master = 'MASTER',
  member = 'MEMBER',
  manager = 'MANAGER'
}

export const LENDING_USER_TYPE = {
  ALL_USER: 'ALL_USER',
  TARGET_USER: 'TARGET_USER'
}

export enum NUMBER {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  ELEVEN = 11,
  TWELVE = 12,
  FIFTEEN = 15,
  TWENTY = 20,
  TWENTY_FOUR = 24,
  TWENTY_NINE = 29,
  THIRTY = 30,
  FORTY = 40,
  SIXTY = 60,
  ONEHUNDRED = 100,
  TWOHUNDRED = 200,
  FIVEHUNDRED = 500,
  THOUSAND = 1000,
  NUM_2147483647 = 9999999999.99999,
}


export enum STRING {
  KUDA = "KUDA",
  ADIL = "ADIL",
  MERAH = "MERAH",
  MARE = "MARE",
  BIRU = "BIRU",
  adil = "adil",
  merah = "merah",
  EMPTY = "",
  ALL = "---All---",
  energy = "energy",
  stats = "stats",
  abilities = "abilities",
  Save = "Save",
  SettingStats = "SettingStats",
  SettingAbilities = "SettingAbilities",
  SettingEnergy = "SettingEnergy",
  COMMON = "COMMON",
  SPEED = "SPEED",
  MUSCLE = "MUSCLE",
  STAMINA = "STAMINA",
  AGILITY = "AGILITY",
  SPIRIT = "SPIRIT",
  IQ = "IQ",
  RANDOM = "RANDOM",
  TURF = "TURF",
  DIRT = "DIRT",
  SHORT = "SHORT",
  MEDIUM = "MEDIUM",
  LONG = "LONG",
  LEVEL_1 = "LEVEL_1",
  LEVEL_2 = "LEVEL_2",
  LEVEL_3 = "LEVEL_3",
  level1 = "level1",
  level2 = "level2",
  level3 = "level3",
  NOT_FULL = "NOT_FULL",
  FULL = "FULL",
  one = "one",
  full = "full",
  STATS = "STATS",
  ABILITY = "ABILITY",
  ENERGY = "ENERGY",
  createItem = "createItem",
  editItem = "editItem",
  DisableTable = "DisableTable",
  SelectItems = "SelectItems",
  RemoveItems = "RemoveItems",
  Attribute_Type = "Attribute_Type",
  Attribute_Level = "Attribute_Level",
  Energy_Effect = "Energy_Effect",
  RARITY = "RARITY",
  TYPE = "TYPE",
  SORT = "SORT",
  OWNER = "OWNER",
  SHOP_ALL = "ALL",
  SHOP_SELECT = "SELECT",
  SHOP_CANCEL = "CANCEL",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  EXPIRED = "EXPIRED",
  normal = "normal",
  GET = "GET",
  SET = "SET",
  start = "start",
  end = "end",
  STR_0 = "0",
  STR_1 = "1",
  STR_2 = "2",
  STR_3 = "3",
  STR_00 = "00",
  STR_01 = "01",
  STR_02 = "02",
  STR_03 = "03",
  STR_04 = "04",
  STR_05 = "05",
  STR_06 = "06",
  STR_07 = "07",
  STR_08 = "08",
  STR_09 = "09",
  ERROR = "ERROR",
  Edit = "Edit",
  Disband = "Disband",
  FREE = "FREE"
}

export enum STRING_ROLE {
  MEMBER = "MEMBER",
  GUEST = "GUEST",
  MANAGER = "MANAGER",
  MASTER = "MASTER",
  OWNER = 'OWNER',
}

export enum STRING_WEATHER {
  BLIZZARD = "BLIZZARD",
  CLOUDY = "CLOUDY",
  HEAT = "HEAT",
  HEAVY_RAIN = "HEAVY_RAIN",
  RAINY = "RAINY",
  SNOWY = "SNOWY",
  STORM = "STORM",
  SUNNY = "SUNNY",
  WEATHER = "WEATHER",
  TIME = "TIME",
  set_weather = "set_weather",
  time_weather = "time_weather",
}

export enum STRING_STATUS {
  OPEN = "OPEN",
  SCHEDULING = "SCHEDULING",
  WAITING = "WAITING",
  LIVE = "LIVE",
  CLOSED = "CLOSED",
  CANCEL = "CANCEL",
  RESULT = "RESULT",
}

export enum STRING_CATEGORY {
  SPECIAL = "SPECIAL",
  RACE = "RACE",
  LENDING = "LENDING",
  GUILD = "GUILD",
  SHOP = "SHOP"
}

export enum STRING_GENDER {
  Male = "Male",
  Female = "Female",
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum STRING_ARMOR_SKIN {
  HEAD = "HEAD",
  BODY = "BODY",
  LEG = "LEG",
  SKIN = "SKIN"
}

export enum STRING_STATS_TYPE {
  SPEED = "SPEED",
  MUSCLE = "MUSCLE",
  STAMINA = "STAMINA",
  AGILITY = "AGILITY",
  SPIRIT = "SPIRIT",
  IQ = "IQ"
}

import styled from 'styled-components'

interface ChooseHorseItemStyledProps {
  currentEnergy: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleTheme = (theme: any, currentEnergy: any) => {
  if (currentEnergy && currentEnergy <= 27) {
    return theme.color.orange
  }
  if (currentEnergy && currentEnergy <= 47) {
    return theme.color.yellow
  }
  if (currentEnergy && currentEnergy <= 73) {
    return theme.color.blue
  }
  if (currentEnergy && currentEnergy <= 100) {
    return theme.color.white
  }
}

const ChooseHorseItemStyled = styled.div<ChooseHorseItemStyledProps>`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0% calc(100% - 20px));
  padding: 1px;

  &:hover {
    background: ${({ theme }) => theme.color.primary};
  }

  .choose-horse-item {
    background-color: ${({ theme }) => theme.color.darkBlue};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0% calc(100% - 20px));
    padding: 16px;
    .new-ui {
      .list-stats {
        clip-path: polygon(calc(100% - 10px) 0,100% 10px,100% 100%,0 100%,0 0);
        background-color: #fff566;
        padding: 1px;
        margin-top: 16px;
        .stats-bg{
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          clip-path: polygon(calc(100% - 10px) 0,100% 10px,100% 100%,0 100%,0 0);
          background-color: #121520;
          padding: 5px;
        }
        .stat-item {
          gap: 5px;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between;
          width: 30%;
          .stat-title{
            margin-right: 10px;
          }
        }
      }
    }
    .mid-container {
      width: 500px;
      gap: 20px;
    }
    .left-container {
      min-width:150px;
      .avatar-container {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-bottom: 10px;

        .avata__img {
          width: 82px;
        }
        
        .avatar {
          width: 82px;
          aspect-ratio: 82/64;
          object-fit: contain;
          object-position: center;
        }
      }

      .energy {
        width: 100%;
        padding-right: 20px;
      }

      /* .energy-container {
        gap: 4px;

        .energy-icon {
          width: 20px;
          aspect-ratio: 1;
        }

        .energy-bar {
          width: 64px;
          height: 4px;
          background-color: rgba(255, 255, 255, 0.3);

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: ${({ currentEnergy }) => currentEnergy}%;
            height: 100%;
            background-color: ${({ theme, currentEnergy }) => handleTheme(theme, currentEnergy)};
          }
        }
      } */
      .energy-text {
        font-size: 14px;
      }
      .link-container {
        .link {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .mid-container {
      .horse-container {
        .name {
          font-size: 18px;
          line-height: 20px;
          width: 140px;
          word-break: break-all;
        }

        .ct_gender {
          margin-top: -6px;
        }

        .bloodline-gender {
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 6px;
        }

        .class-type {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }

        .extra-info {
          gap: 8px;
          margin-bottom: 5px;

          .title {
            font-size: 14px;
            line-height: 14px;
          }

          .content {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }

    .right-container {
      .stat-container-border {
        clip-path: polygon(calc(100% - 10px) 0, 100% 10px, 100% 100%, 0 100%, 0 0);
        padding: 1px;

        .stat-container {
          padding: 12px;
          width: 330px;

          .stat-item {
            gap: 5px;
            justify-content: space-between;
            width: 47%;
            .boots-doping {
              background: transparent;
              border: none;
              width: 30px;
              padding: 0;
              position: relative;
              img{
                width: 100%;
              }
              span {
                color: #fff;
                position: absolute;
                top: -5px;
                background: #ff7a00;
                border-radius: 50%;
                width: 12px;
                height: 12px;
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                right: -5px;
                padding: 8px;
              }
            }
            .stat-title {
              font-size: 12px;
              line-height: 14px;
            }

            .stat-content {
              font-size: 16px;
              line-height: 19px;
              margin-left: 12px;
            }
          }
          .custom-bottom-frame {
            width: 100%;
            margin-bottom: 47px;
            .bottom-frame {
              right: 0;
              bottom: -7px;
              width: 80%;
            }
          }

          .ability-box-container {
            clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%, 0 10px);
            background: ${({ theme }) => theme.color.gradientDarkGreen};
            padding: 1px;
            .ability-box {
              clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%, 0 10px);
              background: ${({ theme }) => theme.color.neutral};
              padding: 8px 5px 7px 10px;
              height: 65px;

              .level {
                margin-right: 15px;
                gap: 3px;
                > div {
                  .rate-star{
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .stat-title {
                font-size: 16px;
              }
            }
            .box-plus {
              .boots-doping {
                background: transparent;
                border: none;
                padding: 0;
                position: relative;
                margin-right: 15px;
        
                &.disable{
                  pointer-events: none;
                  cursor: not-allowed;
                  opacity: 0.5;
                }
                &.disabled{
                  pointer-events: none;
                  cursor: not-allowed;
                  opacity: 0.5;
                }
                img{
                  height: 40px;
                  &.shadown{
                    height: 40px;
                    margin-bottom: 0px;
                  }
                  &.d-block{
                    height: 25px;
                    position: absolute;
                    top: 15%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    margin-bottom: 10px;
                  }
                }
                span {
                  color: #fff;
                  position: absolute;
                  top: -10px;
                  background: #FF7A00;
                  border-radius: 50%;
                  width: 30px;
                  height: 30px;
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  right: -17px;
                  padding: 8px;
                }
              }
            }
          }
          .item-plus {
            width: 100%;
            position: relative;
            height: 65px;
            .frame-union {
              position: absolute;
              left: 0px;
              height: 65px;
              width: 100%;
            }
            .push-doping {
              background: transparent;
              border: none;
              padding: 0;
              position: relative;
              margin-right: 18px;
              &.disabled {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
              }
            }

            img {
              height: 40px;
              &.shadown{
                margin-bottom: -5px;
              }
              &.d-block{
                height: 25px;
                position: absolute;
                top: 15%;
                left: 50%;
                transform: translate(-50%, 0);
                margin-bottom: 10px;
              }
            }
            .name {
              color: #4ef076;
              margin-bottom: 0px;
              font-size: 16px;
            }
            span {
              color: #fff;
              position: absolute;
              top: -10px;
              background: #FF7A00;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              right: -17px;
              padding: 8px;
            }
          }
        }
      }
    }
  }
  .region {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: -5px;
  }
`

export default ChooseHorseItemStyled

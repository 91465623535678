import styled from 'styled-components'

const AbilityBoxStyled = styled.div`
  .ability-box-container {
    &.only-view{
      .push-doping{
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
        margin-left: 5px;
      }
      .ability-box {
        padding: 5px 5px 0px 5px;
      }
      .title {
        font-size: 18px !important;
      }
    }
    clip-path: polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 24px);
    background: ${({ theme }) => theme.color.gradientDarkGreen};
    padding: 1px;

    .ability-box {
      clip-path: polygon(24px 0, 100% 0, 100% 100%, 0 100%, 0 24px);
      background: ${({ theme }) => theme.color.neutral};
      padding: 5px 5px 0px 15px;
      height: 60px;
      .title {
        font-size: 20px;
        line-height: 19px;
      }
    }
    .push-doping{
      background: transparent;
      border: none;
      padding: 0;
      margin-left: 8px;
      margin-right: 5px;
      position: relative;
      margin-bottom: -10px;
      &.use{
        margin-right: 15px;
      }
      &.disable{
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
        margin-right: 5px;
      }
      &.disabled{
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
      &.first{
        margin-left: 20px;
      }

      img{
        height: 40px;
        margin-bottom: 10px;
        &.shadown{
          height: 40px;
          margin-bottom: 10px;
        }
        &.d-block{
          height: 25px;
          position: absolute;
          top: 15%;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      span{
        color: #fff;
        position: absolute;
        top: -10px;
        background: #FF7A00;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -18px;
        padding: 8px;
      }
    }
  }

  .bottom-frame {
    bottom: -10px;
    right: 0;
    width: 100%;
    height: 18px;
  }
`

export default AbilityBoxStyled

import styled from 'styled-components'

const HorseStatsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: #fff;
    font-size: 16px;
  }

  .title {
    font-size: 16px;
    color: #fff566;
    margin-bottom: 0;
  }

  .title__stats {
    display: inline-block;
    width: 60px;
  }

  .content-left {
    width: 120px;

    .horse_runtype {
      word-break: break-all;
      min-height: 60px;
      margin-right: 5px;
    }
  }

  .content-right {
    flex: 1;
    
    .stats {
      height: 26px;
      line-height: 26px;
      font-size: 16px;
      display: flex;
    }

    .content_stats {
      padding-left: 5px;
    }
  }
`

export default HorseStatsContainer

import { CLOSE_BTN, ONE_LINE_ORANGE } from 'assets/images'
import classNames from 'classnames'
import { MODAL_TYPE } from 'models'
import { ReactNode } from 'react'
import { Modal } from 'shared'
import Button from 'shared/Button'
import ConfirmOkModalStyled from './styled'

interface ConfirmOkModalProps {
  toggleIsModalOpen?: (value?: boolean) => void
  onCloseButtonClick: () => void
  onConfirm?: () => void
  message: ReactNode
  title?: string
  isLoading?: boolean
  btnOk?: string | React.ReactNode
  btnCancel?: string | React.ReactNode
  isDisabled?: boolean
  isModalWarning?: boolean
  btnName?: string | React.ReactNode
  heading?: string
  isContentCenter?: boolean
}

function ConfirmOkModal({ isContentCenter, btnOk, btnCancel, toggleIsModalOpen, onCloseButtonClick, onConfirm, message, title, isLoading, isDisabled, isModalWarning, btnName, heading }: ConfirmOkModalProps) {
  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }

  const classTitle = classNames([
    'race-name color-orange text-uppercase d-flex align-items-center flex-column',
    (title === 'completed' || title === 'success!') && 'color-primary'
  ])

  const classTitleFailed = classNames([
    'race-name color-orange text-uppercase d-flex align-items-center flex-column',
    title === MODAL_TYPE.failed && 'color-red'
  ])


  const handleClassTitle = () => {
    switch (title) {
      case MODAL_TYPE.completed:
        return classTitle
      case MODAL_TYPE.success:
        return classTitle
      case MODAL_TYPE.failed:
        return classTitleFailed
      default:
        return classTitle
    }
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <ConfirmOkModalStyled>
        <div className={`choose-horse-modal ${heading ? 'head' : ''}`}>
          {heading && (
            <div className='header'>
              <p>
                <span className={`title ${heading === 'warning' ? 'warning' : ''}`}>
                  {heading}
                </span>
              </p>
              <span className='line__under--title'></span>
            </div>
          )}

          {title &&
            <div className='race-name-container'>
              <p className={handleClassTitle()}>
                <span className='title'>{title}
                  <img className='line-bottom' src={ONE_LINE_ORANGE} alt='' />
                </span>
              </p>
            </div>
          }
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='confirm-horse'>
            <div className={`text message-err ${isContentCenter && 'text-center'}`}>
              {message}
            </div>
            <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
              {
                isModalWarning && <Button buttonName={btnName ?? 'Close'} onClickButton={handleBackModalCancel} width={150} type="btnCancel" />
              }
              {!isModalWarning &&
                (title === MODAL_TYPE.failed || title === MODAL_TYPE.success ?
                  <Button buttonName='OK' onClickButton={handleBackModalCancel} width={150} /> :
                  <>
                    <Button buttonName={btnCancel ? btnCancel : 'No'} onClickButton={handleBackModalCancel} width={150} height={35} type="btnCancel" />
                    <Button
                      buttonName={btnOk ? btnOk : 'Yes'}
                      onClickButton={() => onConfirm?.()}
                      width={150}
                      height={35}
                      margingTop={true}
                      isLoading={isLoading}
                      disabled={isDisabled}
                    />
                  </>)
              }

            </div>
          </div>
        </div>
      </ConfirmOkModalStyled>
    </Modal>
  )
}

export default ConfirmOkModal

import { AbilityBox } from 'features/Horse/components'
import Attributes from 'features/components/Attributes'
import Stats from 'features/components/Stats'
import { Horse, HorseAbility } from 'models'
import { sortAbilities } from 'utils/helper'
import HorseCareerWinRates from '../HorseCareerWinRates'
import HorseBodyInfoStyled from './styled'
interface HorseBodyInfoProps {
  horse: Horse
  isLending?: boolean
  customClass?: string
}
function HorseBodyInfo({ horse, isLending, customClass }: HorseBodyInfoProps) {

  return (
    <HorseBodyInfoStyled>
      {horse && (
        <div className={customClass}>
          <div className={`right-body ${isLending ? 'lending-container' : ''}`}>
            <div className='right'>
              <Attributes horse={horse} />
              <div className='career-win-rates'>
                <HorseCareerWinRates horse={horse} isLending={isLending} />
              </div>
              <div className='stats-body-container'>
                <Stats horse={horse} isLending={isLending} customClass='lending-stats' />
              </div>
              <div className='ability-container row gy-4'>
                {horse && sortAbilities(horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
                  <div className='col-12 col-sm-6' key={index}>
                    <AbilityBox name={skill.name_en} level={skill.level} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </HorseBodyInfoStyled>
  )
}

export default HorseBodyInfo

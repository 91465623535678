/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemContainer } from "./styled";

interface itemProp {
  imgUrl: string
  onClickItem: () => void
  className?: any
  imgDefault?: string
}

const Item = ({ imgUrl, onClickItem, className, imgDefault = '' }: itemProp) => {
  return (
    <ItemContainer onClick={onClickItem} className={className}>
      <div className="img-box">
        <img src={imgUrl} className={`${imgDefault ? "img-default" : ""}`} alt="icon" />
      </div>
    </ItemContainer>
  )
};

export default Item;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { STRING_GENDER, STRING_REGION } from "apps/constants"
import { NFT_BODY, NFT_LEG, NFT_HEAD, NFT_SKIN, MALE_ICON, FEMALE_ICON, REGION_JAPAN, REGION_KOREA } from "assets/images"

const checkExistItem = (type: string, items: any) => {
  if (items?.length <= 0) return null

  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  return res
}

const getImageItem = (type: string, items: any) => {
  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  switch(type) {
    case 'HEAD':
      return res ? res.img : NFT_HEAD;
    case 'BODY':
      return res ? res.img : NFT_BODY;
    case 'LEG':
      return res ? res.img : NFT_LEG
    default:
      return res ? res.img : NFT_SKIN
  }
}

const isUseItem = (type: string, items: any) => {
  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  switch(type) {
    case 'HEAD':
      return res ? true : false;
    case 'BODY':
      return res ? true : false;
    case 'LEG':
      return res ? true : false;
    default:
      return res ? true : false;
  }
}

const getImageGender = (type: any) => {
  switch(type) {
    case STRING_GENDER.MALE:
    case STRING_GENDER.Male:
      return MALE_ICON;
    case STRING_GENDER.FEMALE:
    case STRING_GENDER.Female:
      return FEMALE_ICON;
    default:
      return '';
  }
}

const getImageRegion = (type: any) => {
  switch(type) {
    case STRING_REGION.TOKYO:
      return REGION_JAPAN;
    case STRING_REGION.SEOUL:
      return REGION_KOREA;
    default:
      return '';
  }
}

export { checkExistItem, getImageItem, isUseItem, getImageGender, getImageRegion }

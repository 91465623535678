/* eslint-disable @typescript-eslint/no-explicit-any */

import { AbilityBoxGuild } from 'features/Horse/components'
import Attributes from 'features/components/Attributes'
import Stats from 'features/components/Stats'
import { Horse, HorseAbility } from 'models'
import { sortAbilities } from 'utils/helper'
import HorseCareerWinRates from '../HorseCareerWinRates'
import HorseBodyInfoStyled from './styled'
import { ICON_DOPING, SHADOW_DOPING, UNION } from 'assets/images'
interface HorseBodyInfoProps {
  horse: Horse
  isLending?: boolean
  customClass?: string
}
function HorseBodyInfoGuild({ horse, isLending, customClass }: HorseBodyInfoProps) {
  function handleCurrentAmountStats(currentType: any) {
    const found = horse?.boost_items_equipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.amount)
    } else { return (0) }
  } 
  function handleCurrentImgDoping(currentType: any) {
    const found = horse?.boost_items_equipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.boost?.img)
    } else { return (0) }
  }
  return (
    <HorseBodyInfoStyled>
      {horse && (
        <div className={customClass}>
          <div className={`right-body ${isLending ? 'lending-container' : ''}`}>
            <div className='right'>
              <Attributes horse={horse} />
              <div className='career-win-rates'>
                <HorseCareerWinRates horse={horse} isLending={isLending} />
              </div>
              <div className='stats-body-container'>
                <Stats horse={horse} isLending={isLending} customClass='lending-stats' />
              </div>
              <div className='ability-container row gy-4'>
                {horse && sortAbilities(horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
                  <div className='col-12 col-sm-6' key={index}>
                    <AbilityBoxGuild horse={horse} name={skill.name_en} level={skill.level} />
                  </div>
                ))}
              </div>
              <div 
                className='ability-container doping-plus row gy-4 only-view'
              >
                <div className='col-12 col-sm-6'>
                  <div className="d-flex justify-content-between align-items-center">
                    <img src={UNION} alt='' className='frame-union' />
                    <div className="name">ITEM PLUS</div>
                    {handleCurrentAmountStats('PLUS') == 0 ? (
                      <button
                        className='push-doping new'
                        >
                        <img src={ICON_DOPING} alt='' className='' />
                      </button>
                    ) : (
                      <button
                      className='push-doping'
                        >
                          <img className='shadown' src={SHADOW_DOPING} alt="" />
                          <img src={handleCurrentImgDoping('PLUS')} alt='' className='d-block' />
                        {handleCurrentAmountStats('PLUS') !== 0 && (
                          <span> {handleCurrentAmountStats('PLUS')}</span>
                        )}
                      </button>
                    )}
    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </HorseBodyInfoStyled>
  )
}

export default HorseBodyInfoGuild

/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface paramStyleProps {
  widthM?: any
  heightM?: any
}

const ModalConfirmCommon = styled.div<paramStyleProps>`
  width: ${({ widthM }) => widthM + 'px'};
  height: ${({ heightM }) => heightM + 'px'};
  position: relative;
  background-color: #ef902f;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);

  &:before {
    content: '';
    position: absolute;
    width: ${({ widthM }) => (widthM - 2) + 'px'};
    height: ${({ heightM }) => (heightM - 2) + 'px'};
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  .header {
    margin-bottom: 16px;
    p {
      text-align: center;
      padding-top: 20px;
      position: relative;

      &:after {
        content: '';
        width: 80%;
        height: 1px;
        background: #5e6684;
        bottom: -4px;
        left: 10%;
        position: absolute;
      }

      .title {
        display: inline-block;
        width: fit-content;
        color: #d9903d;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
      }
      .warning {
        color: #d9903d;
      }
    }
  }

  .close-btn {
    top: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }

  .content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      color: #fff;
      font-size: 20px;
      text-align: start;
      padding: 20px 10px 0px 20px;
    }
  }

  .button-box {
    display: flex;
    bottom: 30px;
    position: absolute;
    justify-content: center;
    width: 100%;
    gap: 170px;
  }
  .head-guild {
    color: white;
    background: linear-gradient(
      90deg,
      rgba(18, 21, 32, 0) 0%,
      rgba(78, 242, 125, 0.4) 51.04%,
      rgba(18, 22, 33, 0) 100%
    );
    padding: 0px 40px;
    text-align: center;
    margin: 0 auto;
    width: fit-content;
    position: relative;
    top: -22px;
  }
`

export { ModalConfirmCommon }
